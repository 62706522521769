import React from 'react'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import ItemImage from '../layout/itemImage';

import getItemImagesFromPrismicImageItems from "../../utils/getItemImagesFromPrismicImageItems"

import * as ItemStyles from "./../../styles/item.module.css"
import * as CategoryItemStyles from "./categoryItem.module.css";

const CategoryItem = ({ item }) => {
  const shopItemImages = getItemImagesFromPrismicImageItems(item.imageItems, "shopItem")
  return (
    <div className={CategoryItemStyles.item}>
      <AniLink
        fade
        to={item.shopLink}
        aria-label={`Go to ${item.title} details page`}
      >
        <div className={ItemStyles.itemImageContainer}>
          {item.sold_out && <div className={ItemStyles.soldOutBanner}><span>SOLD OUT</span></div>}
          <ItemImage
            className={CategoryItemStyles.categoryItemImage}
            image={shopItemImages.shopImages[0]}
            imageWidth="33vw"
          />
        </div>
        <div className={CategoryItemStyles.description}>
          <h2 className={CategoryItemStyles.itemTitle}>{item.title}</h2>
          <span className={CategoryItemStyles.price}>{`€ ${item.price}`}</span>
        </div>
      </AniLink>
    </div>
  )
}

export default CategoryItem
