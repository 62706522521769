import React from "react"
import Seo from "../components/seo"

import ShopItemPage from "./../templates/shopItemPage"
import ShopCategoryPage from "../templates/shopCategoryPage"

const UnpublishedPage = props => {
  if (typeof window === "undefined") return null

  return (
    <>
      <Seo noindex />
      {window.__PRISMIC_PREVIEW_DATA__?.PAGE_TYPE === "shop"
        ? <ShopCategoryPage {...props} />
        : <ShopItemPage {...props} />
      }
    </>
  )
}

export default UnpublishedPage
