import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"

import * as ItemStyles from "./../../styles/item.module.css"

const ItemImage = ({ className, image, imageWidth }) => (
  <Img
    fluid={image.fluid}
    className={[ItemStyles.itemImage, className || ""].join(" ")}
    style={{
      width: imageWidth ? imageWidth : "46%",
    }}
  />
)

export default ItemImage

ItemImage.protoTypes = {
  imageWidth: PropTypes.string,
}
