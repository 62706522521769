import React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Category from "../components/category/category"
import usePreviewData from "../utils/usePreviewData"

const ShopCategoryPage = (props) => {
  const category = usePreviewData(props.pageContext.category)

  return (
    <Layout
      isShopRoute={true}
      isCollectionsRoute={false}
      pageTitle={category.name}
    >
      <Seo
        title={category.name}
        keywords={[
          category.name,
          `fashion collection`,
          `slow fashion`,
          `fashion`,
        ]}
        pathname={category.slug}
      />
      <Category category={category} />
    </Layout>
  )
}

export default ShopCategoryPage
