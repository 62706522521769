import React from "react"

import * as CollectionStyles from "./../collection/collection.module.css"
import CategoryItem from "./categoryItem"

import * as CategoryStyles from "./category.module.css";

const Category = ({ category }) => {

  const comingSoonFallback = (
    <div className={CollectionStyles.comingSoonWrapper}>
      <h2 className={CollectionStyles.comingSoonTitle}>
        <span>{category.name}</span> coming soon...
      </h2>
    </div>
  )

  // ? isShop prop can be passed onto this component. Can be used to display prices if the item is for sale
  // * do not display the collection component if there are no items to show
  // * show a coming soon banner instead
  const categoryItems = category.items.length
    ? category.items.map(item => <CategoryItem key={item.uid} item={item} />)
    : comingSoonFallback

  return <div className={CategoryStyles.container}>{categoryItems}</div>
}

export default Category
